import React from 'react'
import { Link } from 'gatsby'

import Layout from '../../components/Layout'

const NotFoundPage = () => {
  return (
    <Layout>
      <div className="not-found-page contact">
        <h1>404 :(</h1>
        <h2>Oops! Está página não pode ser encontrada!</h2>
        <p>Parece que não conseguimos encontrar o que você está procurando.</p>
        <p style={{ marginTop: 0 }}>Talvez a pesquisa possa ajudar.</p>
        <Link to="/pt">Voltar ao ínicio</Link>
      </div>
    </Layout>
  )
}

export default NotFoundPage
